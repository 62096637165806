import * as TYPES from './mutations-types'

export default {
  [TYPES.SET_GROUPS_LIST] (state, loadedList) {
    state.groupsList = loadedList
  },

  [TYPES.SET_GROUPS_USERS] (state, payload) {
    state.groupsUsers = payload
  },

  [TYPES.SET_GROUPS_SOLUTIONS] (state, payload) {
    state.groupsSolutions = payload
  },

  [TYPES.SET_GROUPS_LEARNING_PATHS] (state, payload) {
    state.groupsLearningPaths = payload
  },

  [TYPES.SET_GROUPS_PROGRAMS] (state, payload) {
    state.groupsPrograms = payload
  },

  [TYPES.SET_GROUPS_THEMATIC_PAGES] (state, payload) {
    state.groupsThematicPages = payload
  }
}
