const Management = () => import('./components/Management')
const ManagementManage = () => import('./components/ManagementManage')
const ManagementUserProfiles = () => import('./components/ManagementUserProfiles')
const ManagementUserProfilesManage = () => import('./components/ManagementUserProfilesManage')
// const ManagementThemes = () => import('./components/ManagementThemes')
const ManagementGeneral = () => import('./components/ManagementGeneral')
const ManagementWebhook = () => import('./components/ManagementWebhook')
const ManagementWebhooks = () => import('./components/ManagementWebhooks')
const ManagementWebhooksEdit = () => import('./components/ManagementWebhooksEdit')
const ManagementSurvey = () => import('./components/ManagementSurvey')
const ManagementSurveyEdit = () => import('./components/ManagementSurveyEdit')
const ManagementSurveyEditQuestions = () => import('./components/ManagementSurveyEditQuestions')
const ManageTheme = () => import('./components/ManageTheme')
const ManagementCertificates = () => import('./components/ManagementCertificates')
const ManagementCertificatesEdit = () => import('./components/ManagementCertificatesEdit')
const ManagementBanners = () => import('./components/ManagementBanners')
const ManagementBannersEdit = () => import('./components/ManagementBannersEdit')
const ManagementMsTeams = () => import('./components/ManagementMsTeams')
const ManagementSurveyListSolutions = () => import('./components/ManagementSurveyListSolutions')
const ManagementCertificateListRelations = () => import('./components/ManagementCertificateListRelations')
const ManagementUserProfileListUsers = () => import('./components/ManagementUserProfileListUsers')

export default [
  {
    name: 'management.index',
    path: '/management/profiles',
    component: Management,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'profiles',
        access: 'read'
      }
    },
    children: [
      {
        name: 'management.add',
        path: '/management/profiles/add',
        component: ManagementManage,
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'profiles',
            access: 'write'
          }
        }
      },
      {
        name: 'management.edit',
        path: '/management/profiles/edit/:id',
        component: ManagementManage,
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'profiles',
            access: 'write'
          }
        }
      },
      {
        name: 'management.themes.manage',
        path: '/management/themes/manage/:id?',
        component: ManageTheme,
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'solution_themes',
            access: 'write'
          }
        }
      }
    ]
  },
  {
    name: 'management.settings',
    path: '/management/settings',
    component: ManagementGeneral,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'configurations',
        access: 'read'
      }
    }
  },
  {
    name: 'management.webhooks',
    path: '/management/webhooks',
    component: ManagementWebhooks,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'webhooks',
        access: 'read'
      }
    }
  },
  {
    name: 'management.webhook',
    path: '/management/webhook/:id',
    component: ManagementWebhook,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'webhooks',
        access: 'read'
      }
    }
  },
  {
    name: 'management.webhooks.edit',
    path: '/management/webhooks/edit',
    component: ManagementWebhooksEdit,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'webhooks',
        access: 'write'
      }
    }
  },
  {
    name: 'management.user.profiles',
    path: '/management/user-profiles',
    component: ManagementUserProfiles,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'profiles',
        access: 'read'
      }
    },
    children: [
      {
        name: 'management.user.profiles.add',
        path: '/management/user-profiles/add',
        component: ManagementUserProfilesManage,
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'profiles',
            access: 'write'
          }
        }
      },
      {
        name: 'management.user.profiles.edit',
        path: '/management/user-profiles/edit/:id',
        component: ManagementUserProfilesManage,
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'profiles',
            access: 'write'
          }
        }
      },
      {
        name: 'management.user.profiles.list.users',
        path: '/management/user-profiles/:id?/users',
        component: ManagementUserProfileListUsers,
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'profiles',
            access: 'read'
          }
        }
      }
    ]
  },
  // {
  //   name: 'management.themes',
  //   path: '/management/themes',
  //   component: ManagementThemes,
  //   meta: {
  //     hideNavbar: false,
  //     requiresAuth: true,
  //     permission: {
  //       context: 'solution_themes',
  //       access: 'read'
  //     }
  //   },
  //   children: [
  //     {
  //       name: 'management.themes.manage',
  //       path: '/management/themes/manage/:id?',
  //       component: ManageTheme,
  //       meta: {
  //         hideNavbar: false,
  //         requiresAuth: true,
  //         permission: {
  //           context: 'solution_themes',
  //           access: 'write'
  //         }
  //       }
  //     }
  //   ]
  // },
  {
    name: 'management.survey',
    path: '/management/survey',
    component: ManagementSurvey,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'surveys',
        access: 'read'
      }
    },
    children: [
      {
        name: 'management.survey.list.solutions',
        path: '/management/survey/:id?/solutions',
        component: ManagementSurveyListSolutions,
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'surveys',
            access: 'read'
          }
        }
      }
    ]
  },
  {
    name: 'management.survey.edit',
    path: '/management/survey/edit/:id?',
    component: ManagementSurveyEdit,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'surveys',
        access: 'write'
      }
    }
  },
  {
    name: 'management.survey.edit.questions',
    path: '/management/survey/edit/questions/:id?',
    component: ManagementSurveyEditQuestions,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'surveys',
        access: 'write'
      }
    }
  },
  {
    name: 'management.certificates',
    path: '/management/certificates',
    component: ManagementCertificates,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'certificates',
        access: 'read'
      }
    },
    children: [
      {
        name: 'management.certificates.list.relations',
        path: '/management/certificates/:id?/relations',
        component: ManagementCertificateListRelations,
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'surveys',
            access: 'read'
          }
        }
      }
    ]
  },
  {
    name: 'management.certificates.create',
    path: '/management/certificates/create/:id?',
    component: ManagementCertificatesEdit,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'certificates',
        access: 'write'
      }
    }
  },
  {
    name: 'management.certificates.edit',
    path: '/management/certificates/edit/:id?',
    component: ManagementCertificatesEdit,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'certificates',
        access: 'write'
      }
    }
  },
  {
    name: 'management.banners',
    path: '/management/banners',
    component: ManagementBanners,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'banners',
        access: 'read'
      }
    }
  },
  {
    name: 'management.banners.edit',
    path: '/management/banners/:id?',
    component: ManagementBannersEdit,
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'banners',
        access: 'write'
      }
    }
  },
  {
    name: 'management.gamification',
    path: '/management/gamification',
    component: () => import('./components/Gamification/Gamification'),
    redirect: { name: 'management.gamification.ranking' },
    meta: {
      hideNavbar: false,
      requiresAuth: true,
      permission: {
        context: 'gamification',
        access: 'read'
      }
    },
    children: [
      {
        name: 'management.gamification.ranking',
        path: '/management/gamification/ranking',
        component: () => import('./components/Gamification/Ranking'),
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'gamification',
            access: 'read'
          }
        }
      },
      {
        name: 'management.gamification.level',
        path: '/management/gamification/levels',
        component: () => import('./components/Gamification/Levels'),
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'gamification',
            access: 'read'
          }
        }
      },
      {
        name: 'management.gamification.medal',
        path: '/management/gamification/medals',
        component: () => import('./components/Gamification/Medals'),
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'gamification',
            access: 'read'
          }
        }
      },
      {
        name: 'management.gamification.points.rules',
        path: '/management/gamification/rules',
        component: () => import('./components/Gamification/PointsRule'),
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'gamification',
            access: 'read'
          }
        }
      },
      {
        name: 'management.gamification.rewards',
        path: '/management/gamification/rewards',
        component: () => import('./components/Gamification/Rewards'),
        meta: {
          hideNavbar: false,
          requiresAuth: true,
          permission: {
            context: 'gamification',
            access: 'read'
          }
        }
      }
    ]
  },
  {
    name: 'management.setup.msteams',
    path: '/callback/msteams',
    component: ManagementMsTeams,
    meta: {
      hideNavbar: true,
      requiresAuth: false
    }
  }
]
