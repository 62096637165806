const ModulesList = () => import('./components/ModulesList')
// const ModulesManage = () => import('./components/ModulesManage')

export default [
  {
    name: 'modules.index',
    path: '/modules',
    component: ModulesList,
    meta: {
      hideNavbar: false,
      requiresAuth: false,
      permission: {
        context: 'modules',
        access: 'read'
      }
    }
  // },
  // {
  //   name: 'modules.manage',
  //   path: '/modules/manage/:id?',
  //   component: ModulesManage,
  //   meta: {
  //     hideNavbar: false,
  //     requiresAuth: true,
  //     permission: {
  //       context: 'modules',
  //       access: 'write'
  //     }
  //   }
  }
]
