import * as services from '../services'
import * as TYPES from './mutations-types'

export const attemptGetProgramsList = ({ commit }, pagination) => {
  return services.getProgramsList(pagination)
    .then(programsList => {
      programsList.data.data = programsList.data.data.map(program => {
        if (program.thematicPagesProgram.length > 0) {
          program.thematicPagesProgram = program.thematicPagesProgram.filter(thematicPage =>
            thematicPage.thematicPage.active === 1
          )
        }

        if (program.programSolutions.length > 0) {
          program.programSolutions = program.programSolutions.filter(solution =>
            solution.solution.active === 1
          )
        }

        return program
      })

      commit(TYPES.SET_PROGRAMS_LIST, programsList.data.data)

      return {
        actualPage: programsList.data.actualPage,
        firstPage: programsList.data.firstPage,
        lastPage: programsList.data.lastPage,
        nextPage: programsList.data.nextPage,
        previousPage: programsList.data.previousPage,
        total: programsList.data.total,
        data: programsList.data.data
      }
    })
}

export const attemptSaveProgram = (_, data) => {
  return services.saveProgram(data)
    .then(response => {
      return response
    })
}

export const attemptUpdateActiveProgram = ({ commit }, program) => {
  return services.updateProgram(program)
    .then(response => {
      commit(TYPES.UPDATE_ACTIVE_PROGRAM, response.data)

      return response
    })
}

export const attemptSaveActiveProgram = (_, program) => {
  return services.saveProgram(program)
    .then(response => {
      return response
    })
}

export const attemptUpdateProgram = (_, data) => {
  return services.updateProgram(data)
    .then(response => {
      return response
    })
}

export const attemptManageProgram = ({ commit }, programId) => {
  if (programId == null) {
    commit(TYPES.SET_MANAGE_PROGRAM, null)

    return
  }

  return services.getProgram(programId)
    .then(response => {
      commit(TYPES.SET_MANAGE_PROGRAM, response.data || null)

      return response
    })
}

export const attemptToggleActiveProgram = (_, data) => {
  return services.toggleActiveProgram(data)
    .then(response => {
      return response
    })
}
