import { http } from '@/support/http'
import { createQuery } from '@/support/utils/paginatorQueryBuilder'

export const listModules = (payload) => {
  const queryString = createQuery(payload)

  return http.get(`api/modules?${queryString}`, payload)
}

export const listActiveModules = (payload) => {
  const queryString = createQuery(payload)

  return http.get(`showcase/modules?${queryString}`, payload)
}

export const toggleActiveModule = (idModule) => {
  return http.put(`api/module/${idModule}/toggle`)
}
