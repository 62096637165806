import * as services from '../services'
import * as TYPES from './mutations-types'

export const attemptGetModulesList = ({ commit }, payload) => {
  return services.listModules(payload)
    .then(({ data }) => {
      commit(TYPES.SET_MODULES_ALL_MODULES_LIST, data.data)

      return data
    })
}

export const attemptGetActiveModulesList = ({ commit }, payload) => {
  return services.listActiveModules(payload)
    .then(({ data }) => {
      commit(TYPES.SET_MODULES_ACTIVE_MODULES_LIST, data.data.map(item => item.alias))
    })
}

export const attemptToggleActiveModule = (_, data) => {
  return services.toggleActiveModule(data)
    .then(response => {
      return response
    })
}
