import * as services from '../services'
import * as TYPES from './mutations-types'

export const attemptGetGroupsList = ({ commit }, pagination) => {
  return services.getGroupsList(pagination)
    .then(groupsList => {
      commit(TYPES.SET_GROUPS_LIST, groupsList.data.data)

      return {
        data: groupsList.data.data,
        firstPage: groupsList.data.firstPage,
        lastPage: groupsList.data.lastPage,
        nextPage: groupsList.data.nextPage,
        previousPage: groupsList.data.previousPage,
        actualPage: groupsList.data.actualPage,
        total: groupsList.data.total
      }
    })
}

export const attemptRemoveGroup = (_, data) => {
  return services.removeGroup(data)
    .then(response => {
      return response
    })
}

export const attemptCreateConnection = (_, data) => {
  return services.createConnection(data)
    .then(response => {
      return response
    })
}

export const attemptRemoveConnection = (_, data) => {
  return services.removeConnection(data)
    .then(response => {
      return response
    })
}

export const attemptToggleActiveGroup = (_, idGroup) => {
  return services.toggleActiveGroup(idGroup)
    .then(response => {
      return response
    })
}

export const attemptGetGroup = (_, groupId) => {
  return services.getGroup(groupId)
}

export const attemptCreateGroup = (_, payload) => {
  return services.createGroup(payload)
}

export const attemptUpdateGroup = (_, payload) => {
  return services.updateGroup(payload)
}

export const attemptGetGroupTypesList = (_, pagination) => {
  return services.getGroupTypesList(pagination)
    .then(groupTypesList => {
      return {
        data: groupTypesList.data.data,
        firstPage: groupTypesList.data.firstPage,
        lastPage: groupTypesList.data.lastPage,
        nextPage: groupTypesList.data.nextPage,
        previousPage: groupTypesList.data.previousPage,
        total: groupTypesList.data.total
      }
    })
}

export const attemptGetGroupsUsers = ({ commit }, pagination) => {
  return services.getGroupsUsers(pagination)
    .then(groupsUsers => {
      commit(TYPES.SET_GROUPS_USERS, groupsUsers.data.data)

      return {
        data: groupsUsers.data.data,
        firstPage: groupsUsers.data.firstPage,
        lastPage: groupsUsers.data.lastPage,
        nextPage: groupsUsers.data.nextPage,
        previousPage: groupsUsers.data.previousPage,
        actualPage: groupsUsers.data.actualPage,
        total: groupsUsers.data.total
      }
    })
}

export const attemptGetGroupsSolutions = ({ commit }, pagination) => {
  return services.getGroupsSolutions(pagination)
    .then(groupsSolutions => {
      commit(TYPES.SET_GROUPS_SOLUTIONS, groupsSolutions.data.data)

      return {
        data: groupsSolutions.data.data,
        firstPage: groupsSolutions.data.firstPage,
        lastPage: groupsSolutions.data.lastPage,
        nextPage: groupsSolutions.data.nextPage,
        previousPage: groupsSolutions.data.previousPage,
        actualPage: groupsSolutions.data.actualPage,
        total: groupsSolutions.data.total
      }
    })
}

export const attemptGetGroupsLearningPaths = ({ commit }, pagination) => {
  return services.getGroupsLearningPaths(pagination)
    .then(groupsLearningPaths => {
      commit(TYPES.SET_GROUPS_LEARNING_PATHS, groupsLearningPaths.data.data)

      return {
        data: groupsLearningPaths.data.data,
        firstPage: groupsLearningPaths.data.firstPage,
        lastPage: groupsLearningPaths.data.lastPage,
        nextPage: groupsLearningPaths.data.nextPage,
        previousPage: groupsLearningPaths.data.previousPage,
        actualPage: groupsLearningPaths.data.actualPage,
        total: groupsLearningPaths.data.total
      }
    })
}

export const attemptGetGroupsPrograms = ({ commit }, pagination) => {
  return services.getGroupsPrograms(pagination)
    .then(groupsPrograms => {
      commit(TYPES.SET_GROUPS_PROGRAMS, groupsPrograms.data.data)

      return {
        data: groupsPrograms.data.data,
        firstPage: groupsPrograms.data.firstPage,
        lastPage: groupsPrograms.data.lastPage,
        nextPage: groupsPrograms.data.nextPage,
        previousPage: groupsPrograms.data.previousPage,
        actualPage: groupsPrograms.data.actualPage,
        total: groupsPrograms.data.total
      }
    })
}

export const attemptGetGroupsThematicPages = ({ commit }, pagination) => {
  return services.getGroupsThematicPages(pagination)
    .then(groupsThematicPages => {
      commit(TYPES.SET_GROUPS_THEMATIC_PAGES, groupsThematicPages.data.data)

      return {
        data: groupsThematicPages.data.data,
        firstPage: groupsThematicPages.data.firstPage,
        lastPage: groupsThematicPages.data.lastPage,
        nextPage: groupsThematicPages.data.nextPage,
        previousPage: groupsThematicPages.data.previousPage,
        actualPage: groupsThematicPages.data.actualPage,
        total: groupsThematicPages.data.total
      }
    })
}

export const clearAllGroupsConnections = ({ commit }) => {
  commit(TYPES.SET_GROUPS_USERS, [])
  commit(TYPES.SET_GROUPS_SOLUTIONS, [])
  commit(TYPES.SET_GROUPS_LEARNING_PATHS, [])
  commit(TYPES.SET_GROUPS_PROGRAMS, [])
  commit(TYPES.SET_GROUPS_THEMATIC_PAGES, [])
}
