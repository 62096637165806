import * as services from '../services'
import * as TYPES from './mutations-types'

export const attemptListCertificates = ({ commit }, payload) => {
  return services.listCertificates(payload)
    .then(({ data }) => {
      commit(TYPES.SET_MANAGEMENT_CERTIFICATES_LIST, data.data)

      return data
    })
}

export const attemptListSolutionsCertificate = (_, idCertificate = {}) => {
  return services.listSolutionsCertificate(idCertificate)
    .then((data) => {
      return data.data
    })
}

export const attemptListLearningPathsCertificate = (_, idCertificate = {}) => {
  return services.listLearningPathsCertificate(idCertificate)
    .then((data) => {
      return data.data
    })
}

export const attemptSaveCertificate = (_, payload) => {
  return services.saveCertificate(payload)
    .then(({ data }) => {
      return data
    })
}

export const attemptGetCertificateManagement = (_, payload) => {
  return services.getCertificateManagement(payload)
    .then(({ data }) => {
      return data
    })
}

export const attemptUpdateCertificate = (_, payload) => {
  return services.updateCertificate(payload)
    .then(({ data }) => {
      return data
    })
}

export const attemptToggleCertificateActive = (_, payload) => {
  return services.toggleCertificateActive(payload)
    .then(({ data }) => {
      return data
    })
}

export const attemptGetPreviewCertificate = (_, payload) => {
  return services.getPreviewCertificate(payload)
    .then((data) => {
      return data
    })
}

export const attemptListEntrepreneurProfiles = ({ commit }, payload) => {
  return services.listEntrepreneurProfiles(payload)
    .then(({ data }) => {
      commit(TYPES.SET_MANAGEMENT_ENTREPRENEUR_PROFILES_LIST, data.data)

      return data
    })
}

export const attemptSaveEntrepreneurProfile = (_, payload) => {
  return services.saveEntrepreneurProfile(payload)
}

export const attemptEditEntrepreneurProfile = (_, payload) => {
  return services.editEntrepreneurProfiles(payload)
}

export const attemptToggleActiveEntrepreneurProfile = (_, id) => {
  return services.toggleActiveEntrepreneurProfiles(id)
}

export const attemptListThemes = (_, payload = {}) => {
  return services.listThemes(payload)
}

export const attemptUpdateFeaturedTheme = (_, payload = {}) => {
  return services.updateFeaturedTheme(payload)
}

export const attemptCreateTheme = (_, payload = {}) => {
  return services.createTheme(payload)
}

export const attemptUpdateTheme = (_, payload = {}) => {
  return services.updateTheme(payload)
}

export const attemptToggleTheme = (_, id) => {
  return services.toggleTheme(id)
}

export const attemptListSurveys = ({ commit }, payload = {}) => {
  return services.listSurveys(payload)
    .then((data) => {
      commit(TYPES.SET_SURVEY_LIST, data.data)

      return data
    })
}

export const attemptListSolutionsSurvey = (_, idSurvey = {}) => {
  return services.listSolutionsSurvey(idSurvey)
    .then((data) => {
      return data.data
    })
}

export const attemptGetSurvey = (_, payload = {}) => {
  return services.getSurvey(payload)
}

export const attemptGetSurveyBySlug = (_, payload = {}) => {
  return services.getSurveyBySlug(payload)
}

export const attemptCreateSurvey = (_, payload = {}) => {
  return services.createSurvey(payload)
}

export const attemptUpdateSurvey = (_, payload = {}) => {
  return services.updateSurvey(payload)
}

export const attemptToggleSurvey = (_, id) => {
  return services.toggleSurvey(id)
}

export const attemptListProfiles = ({ commit }, payload = {}) => {
  return services.listProfiles(payload)
    .then(({ data }) => {
      commit(TYPES.SET_MANAGEMENT_PROFILES_LIST, data.data)

      return data
    })
}

export const attemptGetProfile = (_, payload = {}) => {
  return services.getProfile(payload)
    .then(({ data }) => {
      const contexts = [
        'solution',
        'tracks',
        'programs',
        'themes',
        'sessions',
        'session_attendance',
        'users',
        'enrollment',
        'entrepreneur_profiles',
        'profiles',
        'solution_themes',
        'surveys',
        'certificates',
        'banners',
        'gamification',
        'reports',
        'reports_students',
        'reports_enrollments',
        'reports_financial',
        'reports_surveys_answers',
        'reports_canvas_discussion',
        'reports_learning_path_resume',
        'reports_solutions_resume',
        'reports_rae_services',
        'reports_solution_alerts',
        'reports_sas_events',
        'configurations',
        'dashboard',
        'impersonate',
        'search_engine',
        'notification',
        'thematic_pages',
        'filters',
        'mediation_plans',
        'comments',
        'reports_comments',
        'groups',
        'webhooks'
      ]
      const permissions = {}

      for (const i in contexts) {
        let ctx = null
        let access = null

        for (const j in data.profileAccess) {
          if (!Object.prototype.hasOwnProperty.call(data.profileAccess, j)) {
            continue
          }

          if (data.profileAccess[j].contextName === contexts[i]) {
            ctx = data.profileAccess[j].contextName
            access = data.profileAccess[j].contextAccess
          }
        }

        if (ctx !== null) {
          permissions[contexts[i]] = access === 'write' ? 'add.edit' : 'view'
        } else {
          permissions[contexts[i]] = 'disable'
        }
      }

      return {
        id: data.id,
        name: data.name,
        groupProfile: data.groupProfile,
        restrictedAccess: data.restrictedAccess,
        permissions: permissions
      }
    })
}

export const attemptCreateProfile = (_, payload = {}) => {
  return services.createProfile(payload)
    .then(({ data }) => {
      return data
    })
}

export const attemptUpdateProfile = (_, payload = {}) => {
  return services.updateProfile(payload)
    .then(({ data }) => {
      return data
    })
}

export const attemptToggleProfile = (_, id) => {
  return services.toggleProfile(id)
}

export const attemptSaveMsTeamsToken = (_, code) => {
  return services.saveMsTeamsToken(code)
}

export const attemptPingServiceIntegration = (_, alias) => {
  return services.pingServiceIntegration(alias)
    .then(({ data }) => {
      return data
    })
}

export const attemptGetGeneralSettings = () => {
  return services.getGeneralSettings()
}

export const attemptSaveGeneralSettings = (_, settings) => {
  return services.saveGeneralSettings(settings)
}

export const attemptGetFinancialSettings = () => {
  return services.getFinancialSettings()
}

export const attemptSaveFinancialSettings = (_, settings) => {
  return services.saveFinancialSettings(settings)
}

export const attemptListProgramsSurvey = (_, idSurvey = {}) => {
  return services.listProgramsSurvey(idSurvey)
    .then((data) => {
      return data.data
    })
}

export const attemptGetWebhook = (_, payload) => {
  return services.getWebhook(payload)
}

export const attemptListWebhooks = () => {
  return services.listWebhooks()
    .then(({ data }) => {
      return data
    })
}

export const attemptCreateWebhook = (_, payload) => {
  return services.createWebhook(payload)
}

export const attemptCreateWebhookToken = (_, payload) => {
  return services.createWebhookToken(payload)
}

export const attemptListWebhookTokens = (_, payload) => {
  return services.listWebhookTokens(payload)
}

export const attemptRevokeWebhookToken = (_, payload) => {
  return services.revokeWebhookToken(payload)
}
