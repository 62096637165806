import qs from 'qs'

export const createQuery = (queryParams = {}) => {
  const params = {
    query: null,
    filter: null,
    order: null,
    page: 1,
    limit: null
  }
  Object.keys(params).forEach((key) => {
    params[key] = queryParams[key] || null
  })
  return qs.stringify(params)
}
