export const groupsList = getters => {
  return getters.groupsList
}

export const groupsUsers = getters => {
  return getters.groupsUsers
}

export const groupsSolutions = getters => {
  return getters.groupsSolutions
}

export const groupsLearningPaths = getters => {
  return getters.groupsLearningPaths
}

export const groupsPrograms = getters => {
  return getters.groupsPrograms
}

export const groupsThematicPages = getters => {
  return getters.groupsThematicPages
}
