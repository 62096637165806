import * as TYPES from './mutations-types'

export default {
  [TYPES.SET_MODULES_ALL_MODULES_LIST] (state, data) {
    state.modules = data
  },

  [TYPES.SET_MODULES_ACTIVE_MODULES_LIST] (state, data) {
    state.activeModules = data
  }
}
