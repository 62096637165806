import * as TYPES from './mutations-types'

export default {
  [TYPES.SET_USER_TOKEN] (state, token) {
    state.userToken = token
  },
  [TYPES.SET_USER_REFRESH_TOKEN] (state, refreshToken) {
    state.userRefreshToken = refreshToken
  },
  [TYPES.SET_USER_LOGGED_DATA] (state, userData) {
    state.userData = userData
  },
  [TYPES.SET_USER_ROOT] (state, isUserRoot) {
    state.isUserRoot = isUserRoot
  }
}
