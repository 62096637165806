import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import { vuexI18nPlugin } from '@/support/i18n'

import state from './state'
import mutations from './mutations'
import actions from './actions'
import * as getters from './getters'
import modules from './modules'

Vue.use(Vuex)

const storageBlacklist = [
  'offerings/SET_CURRENT_COURSES',
  'offerings/SET_CURRENT_CONFIGS'
]

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
  filter: mutations => (!storageBlacklist.includes(mutations.type))
})

export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin, vuexI18nPlugin],
  state,
  mutations,
  actions,
  getters,
  modules
})
