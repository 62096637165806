import Vue from 'vue'
import Router from 'vue-router'
import beforeEach from './beforeEach'
import { routes as app } from '@/modules'

Vue.use(Router)

const routes = [ ...app ]
const router = new Router({
  routes,
  linkActiveClass: 'is-active-parent',
  linkExactActiveClass: 'is-active',
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach(beforeEach)

export default router
