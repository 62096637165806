const SignIn = () => import('./components/SignIn')

export default [
  {
    name: 'signin',
    path: '/signin',
    component: SignIn,
    meta: {
      requiresAuth: false,
      hideNavbar: true
    }
  }
]
