import { http } from '@/support/http'
import { createQuery } from '@/support/utils/paginatorQueryBuilder'
import { parseToFormDataWithArray } from '@/support/payloadParser'

export const getSystemEmailList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/notification/system_emails?${queryString}`)
}

export const getSystemEmail = (data) => {
  const doReplaceTitle = data.replaceTitle ? '?replaceTitle=true' : ''
  const url = `api/notification/system_email/${data.idSystemEmail}${doReplaceTitle}`

  return http.get(url)
}

export const viewSystemEmail = ({ id }) => {
  const url = `api/notification/system_email/${id}`

  return http.get(url)
}

export const updateSystemEmail = ({ id, data }) => {
  const form = parseToFormDataWithArray(data)

  return http.post(`api/notification/system_email/${id}`, form, {})
}

export const toggleSystemEmail = (idSystemEmail) => {
  return http.post(`api/notification/system_email/${idSystemEmail}/toggle`)
}
