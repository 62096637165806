import { app } from '@/main'

export const VERTICAL_MENU_ITEMS = [
  {
    items: [
      {
        text: 'global:menu.dashboard',
        icon: 'dashboard',
        alias: 'dashboard',
        roles: [ 'ROLE_ADMIN' ],
        link: {
          name: 'dashboard.index'
        }
      },
      {
        text: 'global:menu.solutions',
        icon: 'account_balance',
        roles: [ 'ROLE_ADMIN' ],
        link: {
          name: 'solutions.index'
        }
      },
      {
        text: 'global:menu.tracks',
        icon: 'timeline',
        roles: [ 'ROLE_ADMIN' ],
        link: {
          name: 'tracks.index'
        }
      },
      {
        text: 'global:menu.programs',
        icon: 'programs',
        alias: 'program',
        roles: [ 'ROLE_ADMIN' ],
        link: {
          name: 'programs.index'
        }
      },
      {
        text: 'global:menu.pages',
        icon: 'perm_media',
        roles: [ 'ROLE_ADMIN' ],
        link: {
          name: 'thematicPages.index'
        }
      },
      {
        text: 'global:menu.sessions',
        icon: 'collections_bookmark',
        alias: 'closed-session',
        roles: [ 'ROLE_ADMIN' ],
        link: {
          name: 'sessions.index'
        }
      },
      {
        text: 'global:menu.finances',
        icon: 'finances',
        alias: 'ecommerce',
        roles: [ 'ROLE_ADMIN' ],
        link: {
          name: 'finance.list'
        }
      },
      {
        text: 'global:menu.notifications',
        icon: 'email',
        roles: [ 'ROLE_ADMIN' ],
        link: {
          name: 'notifications.emails'
        }
      },
      {
        text: 'global:menu.users',
        icon: 'person',
        roles: [ 'ROLE_ADMIN' ],
        link: {
          name: 'users.index'
        }
      },
      {
        text: 'global:menu.groups',
        icon: 'people',
        roles: [ 'ROLE_ADMIN' ],
        link: {
          name: 'groups.index'
        }
      },
      {
        text: 'global:menu.reports',
        icon: 'poll',
        roles: [ 'ROLE_ADMIN' ],
        link: {
          name: 'reports.index'
        }
      },
      {
        text: 'global:menu.management',
        icon: 'settings',
        roles: [ 'ROLE_ADMIN' ],
        link: {
          name: 'management.index'
          // name: 'management.underconstruction'
        }
      },
      {
        text: 'global:menu.comments',
        icon: 'comments',
        alias: 'assessment',
        roles: [ 'ROLE_ADMIN' ],
        link: {
          name: 'comments.index'
        }
      },
      {
        text: 'global:menu.tickets',
        icon: 'view-list',
        alias: 'support',
        roles: [ 'ROLE_ADMIN' ],
        link: {
          name: 'tickets.underconstruction'
        }
      },
      {
        text: 'global:menu.logout',
        icon: 'logout',
        bottom: true,
        exact: true,
        roles: [
          'ROLE_ADMIN',
          'ROLE_ROOT'
        ],
        callback: () => {
          app.$store.dispatch('attemptLogout')
        }
      },
      {
        text: 'global:menu.modules',
        icon: 'dashboard',
        roles: [ 'ROLE_ROOT' ],
        alias: 'modules',
        link: {
          name: 'modules.index'
        }
      }
    ]
  }
]
