import { http } from '@/support/http'
import { createQuery } from '@/support/utils/paginatorQueryBuilder'

export const getGroupsList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/groups?${queryString}`)
}

export const createGroup = (payload) => {
  return http.post('api/group', payload)
}

export const updateGroup = ({ id, data }) => {
  return http.put(`api/group/${id}`, data)
}

export const getGroup = (idGroup) => {
  return http.get(`api/group/${idGroup}`)
}

export const toggleActiveGroup = (idGroup) => {
  return http.put(`api/group/${idGroup}/toggle`)
}

export const removeGroup = (idGroup) => {
  return http.delete(`api/group/${idGroup}`)
}

export const createConnection = (payload) => {
  return http.post(`/api/group/${payload.groupId}/sync/${payload.tab}`, payload.data)
}

export const removeConnection = (payload) => {
  return http.delete(`/api/group/${payload.groupId}/${payload.tab}/${payload.connectionId}`)
}

export const getGroupTypesList = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`showcase/groups/types?${queryString}`)
}

export const getGroupsUsers = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/group/list/users?${queryString}`)
}

export const getGroupsSolutions = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/group/list/solutions?${queryString}`)
}

export const getGroupsLearningPaths = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/group/list/learning_paths?${queryString}`)
}

export const getGroupsPrograms = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/group/list/programs?${queryString}`)
}

export const getGroupsThematicPages = (pagination) => {
  const queryString = createQuery(pagination)

  return http.get(`api/group/list/thematic_pages?${queryString}`)
}
